import { Modal } from "antd"
import React from "react"

export function formatZodError(errorJson: Error | any): string[] {
  try {
    const errorObject = JSON.parse(errorJson?.message)
    return errorObject.map((error: any) => {
      const path = error?.path?.join(" -> ")
      const message = error?.message
      return `${path}: ${message}`
    })
  } catch (error) {
    return [error?.message ?? ""]
  }
}

export function formatError(error: Error | any, defaultError = "Something went wrong"): string {
  return typeof error === "string"
    ? error
    : error?.name === "ZodError"
    ? formatZodError(error).map((error) => <div key={error}>{error}</div>)
    : error?.message || defaultError
}

export function showErrorModal(error: Error | any) {
  Modal.error({
    title: "Error",
    content: <div className="break-words">{formatError(error)}</div>,
    zIndex: 9999,
    okButtonProps: {
      className: "!bg-primary-blue !text-white hover:opacity-[0.8]",
    },
    closable: true,
    maskClosable: true,
  })
}
